<template>
  <v-row>
    <v-col cols="12">
      <!-- Default User Registration -->
      <v-container>
        <v-row style="background-color:#ffffff; ">
          <v-col md="12" align="center">
            <v-layout row wrap>
              <v-col cols="12" md="6" offset-md="3" align="center">
                <h3>Join qikPharma</h3>
                <p>A community of trusted pharma manufacturers, distributors, pharmacies, medics, and pharma consumers. Come and join us!</p>
              </v-col>
            </v-layout>
            
            <v-layout row wrap>
              <v-col cols="12" align="center">
                  <router-link
                    to="/login"
                    style="text-decoration: none;color: black;"
                  >
                    <span> Already registered? </span>
                    <span  style="text-decoration: none;color: #72bd61;"> Login </span>
                  </router-link>
              </v-col>
            </v-layout>

            <v-layout row wrap>
                <v-col cols="12" md="6" offset-md="3">
                      <v-layout>
                        <v-text-field
                          v-model="registrationData.name"
                          outlined
                          dense
                          label="Name of owner"
                          prepend-inner-icon="mdi-account"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-layout>

                      <v-layout>
                        <v-text-field
                          v-model="registrationData.email"
                          outlined
                          dense
                          label="Email Address"
                          prepend-inner-icon="mdi-email"
                          @keyup="emailValidation"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-layout>

                      <v-layout>
                        <v-text-field
                          :prepend-inner-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                          :rules="[rules.required, rules.min]"
                          :type="show3 ? 'text' : 'password'"
                          name="password"
                          label="Password"
                          outlined
                          dense
                          hint="At least 8 characters"
                          v-model="registrationData.password"
                          class="input-group--focused"
                          @click:prepend-inner="show3 = !show3"
                        ></v-text-field>
                      </v-layout>
                      

                      <v-layout row>
                        <v-col cols="12" md="3">
                          <v-select
                            v-model="selectCode"
                            :hint="`${selectCode.country}, ${selectCode.code}`"
                            :items="telCodes"
                            item-text="country"
                            item-value="code"
                            label="Code"
                            persistent-hint
                            return-object
                            single-line
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="9">
                          <v-text-field
                            v-model="phone"
                            outlined
                            dense
                            label="Phone Number"
                            prepend-inner-icon="mdi-phone"
                            :rules="[rules.required]"
                            @mouseout="editNumber"
                          ></v-text-field>
                        </v-col>
                      </v-layout>

                      <v-layout>
                        <v-select
                          :items="roles"
                          label="Select Role"
                          v-model="registrationData.roleId"
                          prepend-inner-icon="mdi-account-tie"
                          outlined
                          dense
                          item-text="name"
                          item-value="roleId"
                        ></v-select>
                      </v-layout>

                       <v-layout>
                        <v-text-field
                          v-model="registrationData.address"
                          outlined
                          dense
                          label="Address"
                          prepend-inner-icon="mdi-map-marker"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-layout>

                      <v-layout>
                        <v-select
                          :items="country"
                          label="Select Country"
                          v-model="registrationData.country"
                          prepend-inner-icon="mdi-flag"
                          outlined
                          dense
                          item-text="name"
                          @change="getCountryStates"
                        ></v-select>
                      </v-layout>

                      <v-layout>
                        <v-select
                          :items="states"
                          label="Select State"
                          prepend-inner-icon="mdi-map-marker"
                          outlined
                          dense
                          item-text="name"
                          v-model="registrationData.state"
                        ></v-select>
                      </v-layout>

                      <v-layout>
                        <v-text-field
                          v-model="registrationData.referralCode"
                          outlined
                          prepend-inner-icon="mdi-account-supervisor"
                          dense
                          label="Referal Code (Optional)"
                        ></v-text-field>
                      </v-layout>

                      <v-layout v-show="showSubmit">
                        <v-col cols="12" >
                          <v-btn class="buttonStyle" @click="submitReg" block>
                            Submit
                          </v-btn>
                        </v-col>
                      </v-layout>
                      <v-layout wrap>
                        <v-col cols="12" align="left">
                                <v-switch
                                  v-model="checkbox"
                                  @change="confirmTCheck"
                                >
                                  <template v-slot:label>
                                    <div>
                                      By continuing, I agree to the
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <a
                                            target="_blank"
                                            :href="'/#/terms-and-conditions'"
                                            @click.stop
                                            v-on="on"
                                            style="color:#72BD61;text-decoration:none;"
                                          >
                                            Terms & Conditions
                                          </a>
                                        </template>
                                      </v-tooltip>
                                      &
                                      <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                          <a
                                            target="_blank"
                                            :href="'/#/'"
                                            @click.stop
                                            v-on="on"
                                            style="color:#72BD61;text-decoration:none;"
                                          >
                                            Privacy Policy
                                          </a>
                                        </template>
                                      </v-tooltip>
                                    </div>
                                  </template>
                                </v-switch>
                          </v-col>
                        </v-layout>

                      <v-layout>
                        <v-col cols="12" align="left">
                          <v-progress-linear
                            color="#7CBF46"
                            v-show="loader"
                            :indeterminate="true"
                          ></v-progress-linear>
                        </v-col>
                      </v-layout>

                      <v-layout color="#BD2121">
                        <v-col cols="12">
                          <h5 class="text-danger">
                            {{ errorMessage }}
                          </h5>
                        </v-col>
                      </v-layout>
                    </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-container>

      <!--- Manufactures Update form ---->
      <v-dialog
        v-model="dialogManufacturer"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#000000"
          >
            <v-btn
              icon
              dark
              @click="dialogManufacturer = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Manufacture's Profile Bio</v-toolbar-title>
          </v-toolbar>
          <v-layout wrap>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="6" offset-md="3">

                      <v-layout>
                        <v-text-field
                          v-model="updatemanufacturersData.companyName"
                          outlined
                          dense
                          label="Company Name"
                          prepend-inner-icon="mdi-domain"
                        ></v-text-field>
                      </v-layout>

                      <v-layout>
                        <v-text-field
                          v-model="updatemanufacturersData.companyNumber"
                          outlined
                          dense
                          label="Company Registration Number (RC Number)"
                          prepend-inner-icon="mdi-account-check-outline"
                        ></v-text-field>
                      </v-layout>

                      <v-layout>
                        <v-select
                          v-model="updatemanufacturersData.companyTypeId"
                          :items="companyType"
                          label="Company type"
                          prepend-inner-icon="mdi-flag"
                          outlined
                          dense
                          item-text="name"
                          item-value="companyTypeId"
                        ></v-select>
                      </v-layout>

                      <v-layout>
                        <v-textarea
                          v-model="updatemanufacturersData.description"
                          outlined
                          dense
                          label="Nature of Business (Description)"
                        ></v-textarea>
                      </v-layout>

                      <v-layout>
                        <v-col cols="12">
                          <label style="font-size:14px;"
                            >Type of medications to be sold or imported</label
                          >
                          <v-layout
                            v-for="(medication, index) in typeOfMedication"
                            :key="index"
                          >
                            <v-checkbox
                              v-model="updatemanufacturersData.typeOfMedicationIds"
                              :label="medication.name"
                              :value="medication.typeOfMedicationId"
                            ></v-checkbox>
                          </v-layout>
                        </v-col>
                      </v-layout>

                      <v-row>
                        <v-progress-linear
                          color="#7CBF46"
                          v-show="loader2"
                          :indeterminate="true"
                        ></v-progress-linear>
                      </v-row>

                      <v-row>
                        <v-col cols="12" align="center">
                          <v-btn class="buttonStyle" @click="updateManufacturer">
                            Submit
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-layout color="#BD2121">
                        <v-col cols="12">
                          <h5 class="text-danger">
                            {{ errorMessage2 }}
                          </h5>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-row>
            </v-col>
          </v-layout>
          
        </v-card>
      </v-dialog>

      <!--- Distributors Update form ---->
      <v-dialog
        v-model="dialogDistributor"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#000000"
          >
            <v-btn
              icon
              dark
              @click="dialogDistributor = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Distributor's Profile Bio</v-toolbar-title>
          </v-toolbar>
          <v-layout wrap>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="6" offset-md="3">

                      <v-layout>
                        <v-text-field
                          v-model="updatemanufacturersData.companyName"
                          outlined
                          dense
                          label="Company Name"
                          prepend-inner-icon="mdi-domain"
                        ></v-text-field>
                      </v-layout>

                      <v-layout>
                        <v-text-field
                          v-model="updatemanufacturersData.companyNumber"
                          outlined
                          dense
                          label="Company Registration Number (RC Number)"
                          prepend-inner-icon="mdi-account-check-outline"
                        ></v-text-field>
                      </v-layout>

                      <v-layout>
                        <v-select
                          v-model="updatemanufacturersData.companyTypeId"
                          :items="companyType"
                          label="Company type"
                          prepend-inner-icon="mdi-flag"
                          outlined
                          dense
                          item-text="name"
                          item-value="companyTypeId"
                        ></v-select>
                      </v-layout>

                      <v-layout>
                        <v-textarea
                          v-model="updatemanufacturersData.description"
                          outlined
                          dense
                          label="Nature of Business (Description)"
                        ></v-textarea>
                      </v-layout>

                      <v-layout>
                        <v-col cols="12">
                          <label style="font-size:14px;"
                            >Type of medications to be sold or imported</label
                          >
                          <v-layout
                            v-for="(medication, index) in typeOfMedication"
                            :key="index"
                          >
                            <v-checkbox
                              v-model="updatemanufacturersData.typeOfMedicationIds"
                              :label="medication.name"
                              :value="medication.typeOfMedicationId"
                            ></v-checkbox>
                          </v-layout>
                        </v-col>
                      </v-layout>

                      <v-row>
                        <v-progress-linear
                          color="#7CBF46"
                          v-show="loader2"
                          :indeterminate="true"
                        ></v-progress-linear>
                      </v-row>

                      <v-row>
                        <v-col cols="12" align="center">
                          <v-btn class="buttonStyle" @click="updateDistributor">
                            Submit
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-layout color="#BD2121">
                        <v-col cols="12">
                          <h5 class="text-danger">
                            {{ errorMessage2 }}
                          </h5>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-row>
            </v-col>
          </v-layout>
          
        </v-card>
      </v-dialog>

      <!--- Retailers Update form ---->
      <v-dialog
        v-model="dialogRetailer"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#000000"
          >
            <v-btn
              icon
              dark
              @click="dialogRetailer = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Retailer's Profile Bio</v-toolbar-title>
          </v-toolbar>
          <v-layout wrap>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="6" offset-md="3">
                      <v-layout>
                        <v-text-field
                          v-model="updateRetailersData.companyName"
                          outlined
                          dense
                          label="Company Full Name"
                          prepend-inner-icon="mdi-domain"
                        ></v-text-field>
                      </v-layout>

                      <v-layout>
                        <v-text-field
                          v-model="updateRetailersData.companyNumber"
                          outlined
                          dense
                          label="Company Registration Number (RC Number)"
                          prepend-inner-icon="mdi-account-check-outline"
                        ></v-text-field>
                      </v-layout>

                      <v-layout>
                        <v-textarea
                          v-model="updateRetailersData.description"
                          outlined
                          dense
                          label="Nature of Business (Description)"
                        ></v-textarea>
                      </v-layout>

                      <v-row>
                        <v-progress-linear
                          color="#7CBF46"
                          v-show="loader2"
                          :indeterminate="true"
                        ></v-progress-linear>
                      </v-row>

                      <v-row>
                        <v-col cols="12" align="center">
                          <v-btn class="buttonStyle" @click="updateRetailer">
                            Submit
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-layout color="#BD2121">
                        <v-col cols="12">
                          <h5 class="text-danger">
                            {{ errorMessage2 }}
                          </h5>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-row>
            </v-col>
          </v-layout>
          
        </v-card>
      </v-dialog>

      <!--- Professional Update form ---->
      <v-dialog
        v-model="dialogProfessional"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="#000000"
          >
            <v-btn
              icon
              dark
              @click="dialogProfessional = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Professional's Profile Bio</v-toolbar-title>
          </v-toolbar>
          <v-layout wrap>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="6" offset-md="3">

                      <v-layout>
                        <v-col cols="12">
                          <v-layout>
                            <v-col cols="12" align="center" justify="center">
                              <v-icon size="100" @click="onPickAvatar">
                                mdi-camera
                              </v-icon>
                              <h5>Choose Avatar</h5>
                              <input
                                type="file"
                                style="display:none;"
                                ref="fileInput"
                                accept="image/*"
                                @change="avatarPicked"
                              />
                            </v-col>
                          </v-layout>
                          <!-- Preview Image --->
                          <v-layout>
                            <v-col cols="12" v-show="preview">
                              <v-layout>
                                <v-img
                                  class="centerImg"
                                  contain
                                  :ref="'image'"
                                  height="150"
                                  align="center"
                                >
                                </v-img>
                              </v-layout>
                            </v-col>
                          </v-layout>
                        </v-col>
                      </v-layout>

                      <v-layout>
                        <v-select  
                          label="Select a Profession"
                          :items="profesions"
                          v-model="updateProfessionalData.profession"
                          outlined
                          prepend-inner-icon="mdi-account-tie-hat"
                          dense>
                        </v-select>
                      </v-layout>

                      <v-layout>
                        <v-text-field
                          v-model="updateProfessionalData.licenseNumber"
                          outlined
                          dense
                          label="License Number"
                          prepend-inner-icon="mdi-account-check-outline"
                        ></v-text-field>
                      </v-layout>

                      <v-layout>
                        <v-file-input
                          v-model="updateProfessionalData.licenseImage"
                          label="Upload licence image"
                          outlined
                          dense
                        ></v-file-input>
                      </v-layout>


                      <v-row>
                        <v-progress-linear
                          color="#7CBF46"
                          v-show="loader2"
                          :indeterminate="true"
                        ></v-progress-linear>
                      </v-row>

                      <v-row>
                        <v-col cols="12" align="center">
                          <v-btn class="buttonStyle" @click="updateProfessional">
                            Submit
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-layout color="#BD2121">
                        <v-col cols="12">
                          <h5 class="text-danger">
                            {{ errorMessage2 }}
                          </h5>
                        </v-col>
                      </v-layout>
                    </v-col>
                  </v-row>
            </v-col>
          </v-layout>
          
        </v-card>
      </v-dialog>

      <!--- Success Modal ---->
      <v-dialog v-model="modalSuccess" persistent max-width="350">
                          <v-card
                            style="border-radius:15px;height:auto; padding:10px;"
                          >
                            <v-card-text>
                              <v-row wrap>
                                <v-col cols="12" align="center" justify="center">
                                  <v-icon style="color:#456928; font-size:42px;"
                                    >mdi-checkbox-marked-circle-outline</v-icon
                                  >
                                </v-col>
                              </v-row>
                              <v-row wrap>
                                <v-col cols="12" align="center" justify="center">
                                  <h4>Registration Successful</h4>
                                  <p>
                                    Your registration has been
                                    submitted and awaiting admin approval
                                  </p>
                                </v-col>
                              </v-row>

                              <v-row wrap>
                                <v-col cols="12" align="center" justify="center">
                                  <v-btn
                                    block
                                    dark
                                    color="#456928"
                                    @click="redirectLogin"
                                  >
                                    OK
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
      </v-dialog>

      <!--- Success Modal ---->
      <v-dialog v-model="modalSuccess2" persistent max-width="350">
                          <v-card
                            style="border-radius:15px;height:auto; padding:10px;"
                          >
                            <v-card-text>
                              <v-row wrap>
                                <v-col cols="12" align="center" justify="center">
                                  <v-icon style="color:#456928; font-size:42px;"
                                    >mdi-checkbox-marked-circle-outline</v-icon
                                  >
                                </v-col>
                              </v-row>
                              <v-row wrap>
                                <v-col cols="12" align="center" justify="center">
                                  <h4>Registration Successful</h4>
                                  <p>
                                    Your registration was successful
                                  </p>
                                </v-col>
                              </v-row>

                              <v-row wrap>
                                <v-col cols="12" align="center" justify="center">
                                  <v-btn
                                    block
                                    dark
                                    color="#456928"
                                    @click="redirectLogin"
                                  >
                                    OK
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
      </v-dialog>

    </v-col>
  </v-row>
</template>

<script>
import { Country, State } from "country-state-city";

export default {
  name: "Registration",

  data: () => ({
    dialogManufacturer: false,
    dialogDistributor:false,
    dialogRetailer:false,
    dialogProfessional:false, 
    modalSuccess:false,
    modalSuccess2:false,
    show3: false,
    errorMessage: "",
    errorMessage2:"",
    e1: 1,
    loader: false,
    loader2:false,
    checkbox: false,
    showSubmit: false,
    roles:[],
    profesions:[
      'Doctor', 'Pharmacist','Consultant','Nurses','Homecare Assistants'
    ],
    registrationData: {
      roleId: "",
      name: "",
      email: "",
      password: "",
      phoneNumber: "",
      address: "",
      country: "",
      state: "",
      referralCode: "",
    },

    updatemanufacturersData: {
      companyName: "",
      companyNumber: "",
      description: "",
      companyTypeId: "",
      typeOfMedicationIds: [],
    },

    updateRetailersData: {
      companyName: "",
      companyNumber: "",
      description: ""
    },

    updateProfessionalData: {
      profession: "",
      licenseNumber: "",
      licenseImage: null,
    },

    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    country: [],
    states: [],
    cities: [],
    selectedCountry: {},
    selectedMedicationType: [],
    companyType: [],
    typeOfMedication: [],
    userId:"",
    preview:false,
    image: "",
    selectCode: { country: 'Nigeria', code: '+234' },
    telCodes: [
          { country: 'Nigeria', code: '+234' },
    ],
    phone:""
  }),
  computed: {
    phoneNumber: {
      // getter
      get() {
        return this.selectCode.code + this.phone
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        [this.selectCode.code, this.phone] = newValue.split(' ');
      }
    }
  },
  mounted() {
    const self = this;
    self.getCountry();

    self.$http
      .get(`${process.env.VUE_APP_URL}default`)
      .then(function(response) {
        self.companyType = response.data.data.companyTypes;
        self.typeOfMedication = response.data.data.typeOfMedications;
        response.data.data.roles.forEach((role) => {
          if (role.name !== "Admin") {
             self.roles.push(role);
          }
        });
    });

  },
  methods: {
    editNumber() {
      const oldPhone = this.phone;
     
      if(oldPhone.charAt(0) == 0)
      {
        var newPhoneNumber = oldPhone.substring(1);
        this.phone = newPhoneNumber;
      }
     
    },
    submitReg() {
      this.loader = true;
      this.errorMessage = "";
      this.registrationData.phoneNumber = this.phoneNumber;

      this.$http
        .post(`${process.env.VUE_APP_URL}signup`, this.registrationData,{
          headers: {
            'apiKey':`${process.env.VUE_APP_KEY}`
          }
        })
        .then((response) => {
          console.log(response);
          this.userId = response.data.data.userId;
          
           if (response.data.data.Role.name == "User") {
            //this.redirectLogin();
            this.modalSuccess2 = true;
          }
          if (response.data.data.Role.name == "Manufacturer") {
            this.loader = false;
            this.dialogManufacturer = true;
          }
          if (response.data.data.Role.name == "Distributor") {
            this.loader = false;
            this.dialogDistributor = true;
          }
          if (response.data.data.Role.name == "Retailer") {
            this.loader = false;
            this.dialogRetailer = true;
          }
          if (response.data.data.Role.name == "Professional") {
            this.loader = false;
            this.dialogProfessional = true;
          }
        })
        .catch((error) => {
          this.loader = false;
          if (error.response.data.data.length >= 1) {
            this.errorMessage = error.response.data.data.toString();
          }
        });
    },

    confirmTCheck() {
      if (this.checkbox == false) {
        this.showSubmit = false;
      } else {
        //this.showSubmit = true;
        this.emailValidation();
      }
    },

    emailValidation() {
      if (this.registrationData.email == "" || this.checkbox == false) {
        this.showSubmit = false;
      } else {
        this.showSubmit = true;
      }
    },

    updateManufacturer() {
        this.loader2 = true;
        this.errorMessage2 = "";
        this.$http
        .post(`${process.env.VUE_APP_URL}manufacturer/` + this.userId, this.updatemanufacturersData)
        .then(() => {
          this.loader2 = false;
          this.modalSuccess = true;
        })
        .catch((error) => {
          this.loader2 = false;
          if (error.response.data.data.length >= 1) {
            this.errorMessage2 = error.response.data.data.toString();
          }
        });
    },

    updateDistributor() {
        this.loader2 = true;
        this.errorMessage2 = "";
        this.$http
        .post(`${process.env.VUE_APP_URL}distributor/` + this.userId, this.updatemanufacturersData)
        .then(() => {
          this.loader2 = false;
          this.modalSuccess = true;
        })
        .catch((error) => {
          this.loader = false;
          if (error.response.data.data.length >= 1) {
            this.errorMessage2 = error.response.data.data.toString();
          }
        });
    },

    updateRetailer() {
        this.loader2 = true;
        this.errorMessage2 = "";
        this.$http
        .post(`${process.env.VUE_APP_URL}retailer/` + this.userId, this.updateRetailersData)
        .then(() => {
          this.loader2 = false;
          this.modalSuccess = true;
        })
        .catch((error) => {
          this.loader = false;
          if (error.response.data.data.length >= 1) {
            this.errorMessage2 = error.response.data.data.toString();
          }
        });
    },

    updateProfessional() {
        this.loader2 = true;
        this.errorMessage2 = "";
        
        const formdata = new FormData();

        formdata.append("profileImage", this.image);
        formdata.append("licenseImage", this.updateProfessionalData.licenseImage);
        formdata.append("profession", this.updateProfessionalData.profession);
        formdata.append("licenseNumber", this.updateProfessionalData.licenseNumber);

        this.$http
          .post(`${process.env.VUE_APP_URL}user/update-professional-details/` + this.userId, formdata)
          .then(() => {
            this.loader2 = false;
            this.modalSuccess = true;
          })
          .catch((error) => {
            this.loader = false;
            this.errorMessage = error.response.data.message;
          });
    },

    getCountry() {
      this.country = Country.getAllCountries();
    },

    getCountryStates(evt) {
      this.country.forEach((element) => {
        if (element.name == evt) {
          this.states = State.getStatesOfCountry(element.isoCode);
        }
      });
    },

    redirectLogin() {
      this.$router.push("/login");
    },

    onPickAvatar() {
      this.$refs.fileInput.click();
    },

    avatarPicked(e) {
      this.preview = true;
      const selectedFiles = e.target.files[0];

      this.image = selectedFiles;

      let reader = new FileReader();
      reader.onload = () => {
        this.$refs.image.src = reader.result;
      };

      reader.readAsDataURL(this.image);
    },

  },
};
</script>

<style scoped>
.box1 {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff;
}

.box2 {
  background: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  color: #171a17;
}

.barcodeTitle {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.barcodeText {
  color: #c2c2c2;
  font-size: 12px;
  margin-top: 15px;
  text-align: center;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  border-radius:10px !important;
}

.buttonStyle {
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
}
</style>
